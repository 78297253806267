import { ReactNode, useState, useEffect, useRef, KeyboardEvent } from 'react'
import Fuse from 'fuse.js'
import { twMerge } from 'tailwind-merge'

import Spinner from '~/src/components/generic/Spinner'
import PlusIcon from '~/src/components/generic/PhosphorIcons/PlusIcon'
import Input from '~/src/components/generic/Input'

import useHotKeys from '~/hooks/useHotKeys'

type SearchableDropdownProps<T> = {
  value: string
  options: T[]
  onFocus?: () => void
  onBlur?: (v: string) => void
  onChange?: (v: string) => void
  onSelect: (v: T) => void
  onDropdownCta?: (v: string) => void
  renderOption: (v: T) => ReactNode
  dropdownCtaLabel?: string
  placeholder?: string
  searchKeys?: string[]
  className?: string
  useBorderlessInput?: boolean
  fuseThreshold?: number
  optionsLoading?: boolean
  maxNumOptions?: number
  onInputRefSet?: (r: HTMLDivElement) => void
  inputClassName?: string
  disabled?: boolean
}

const SearchableDropdown = <T,>({
  className,
  value,
  onFocus,
  onBlur,
  onChange,
  onSelect,
  options,
  renderOption,
  placeholder,
  searchKeys,
  dropdownCtaLabel = 'add new',
  onDropdownCta,
  fuseThreshold = 0.2,
  optionsLoading,
  maxNumOptions = 100,
  onInputRefSet,
  inputClassName,
  disabled = false,
}: SearchableDropdownProps<T>): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false)
  const [editingValue, setEditingValue] = useState(value)
  const [highlightedRow, setHighlightedRow] = useState(-1)
  const skipNextOnBlur = useRef(false)
  const optionsContainerEl = useRef<HTMLDivElement>(null)
  const optionEls = useRef<(HTMLDivElement | null)[]>([])
  const inputEl = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    highlightedRow !== null &&
      optionEls.current[highlightedRow] &&
      optionEls.current[highlightedRow]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
  }, [highlightedRow])

  useEffect(() => {
    if (optionsContainerEl.current && optionsContainerEl.current.scrollTop !== 0) {
      optionsContainerEl.current.scrollTop = 0
    }
    setHighlightedRow(-1)
  }, [editingValue])

  useHotKeys('a', () => setTimeout(() => inputEl.current?.focus()), {
    description: 'Select the account search box',
    ctx: 'visibility',
  })

  const getRelevantOptions = (opts: T[]) =>
    new Fuse(opts, searchKeys ? { keys: searchKeys, threshold: fuseThreshold } : {})
      .search(editingValue)
      .map(({ item }) => item)

  const relevantOpts = editingValue === '' ? options : getRelevantOptions(options)

  const handleKeyPress = (e: KeyboardEvent<Element>): void => {
    if (['ArrowUp', 'ArrowDown', 'Enter'].includes(e.key)) {
      e.preventDefault()
    }

    if (e.key === 'ArrowUp') {
      setHighlightedRow((hr): number => Math.max(0, hr - 1))
    } else if (e.key === 'ArrowDown') {
      setHighlightedRow((hr): number =>
        Math.min(relevantOpts.length - (onDropdownCta ? 0 : 1), hr + 1)
      )
    } else if (e.key === 'Enter') {
      if (relevantOpts[highlightedRow]) {
        onSelect(relevantOpts[highlightedRow])
        skipNextOnBlur.current = true
        inputEl.current?.blur()
      } else if (onDropdownCta) {
        onDropdownCta(editingValue)
      }
    }
  }

  return (
    <div
      className={twMerge('relative', className)}
      data-testid="searchable-dropdown-autocomplete"
    >
      <Input
        className={twMerge('text-base sm:text-sm', inputClassName)}
        ref={(r) => {
          inputEl.current = r
          r && onInputRefSet?.(r)
        }}
        value={isEditing ? editingValue : value}
        placeholder={placeholder}
        type="text"
        onChange={(event): void => {
          setEditingValue(event.target.value)
          onChange && onChange(event.target.value)
        }}
        onKeyDown={handleKeyPress}
        onFocus={(): void => {
          setIsEditing(true)
          setEditingValue(value)
          onFocus && onFocus()
        }}
        onBlur={(): void => {
          setIsEditing(false)
          setEditingValue('')

          if (skipNextOnBlur.current) {
            skipNextOnBlur.current = false
            return
          }

          editingValue !== value && onBlur && onBlur(editingValue)
        }}
        disabled={disabled}
      />
      {isEditing && relevantOpts.length > 0 ? (
        <div className="absolute z-50 mt-0.5 flex w-full min-w-[100px] flex-col overflow-hidden rounded-b-md border border-solid bg-white shadow-xl">
          <div ref={optionsContainerEl} className="max-h-72 overflow-y-auto">
            {optionsLoading && !relevantOpts.length && (
              <div className="flex h-8 w-full items-center justify-center">
                <Spinner size={28} color="#fff" />
              </div>
            )}
            {relevantOpts.slice(0, maxNumOptions).map(
              (o: T, idx): ReactNode => (
                <div
                  key={idx}
                  ref={(el) => {
                    optionEls.current[idx] = el
                  }}
                  className={twMerge(
                    'w-full cursor-pointer px-2 py-1 hover:bg-gray-50 hover:text-gray-900',
                    idx === highlightedRow && 'bg-gray-50 text-gray-900'
                  )}
                  onMouseDown={(): void => {
                    skipNextOnBlur.current = true
                    onSelect(o)
                  }}
                  data-testid="searchable-dropdown-option"
                >
                  {renderOption(o)}
                </div>
              )
            )}
          </div>
          {onDropdownCta ? (
            <div
              onMouseDown={(e): void => {
                e.stopPropagation()
                skipNextOnBlur.current = true
                onDropdownCta(editingValue)
              }}
              className={twMerge(
                'min-h-7 w-full cursor-pointer border-t-[0.5px] border-solid bg-gray-75 p-2 text-xs text-white hover:bg-gray-500',
                !relevantOpts[highlightedRow] && 'bg-gray-500'
              )}
            >
              <PlusIcon size={20} />
              {dropdownCtaLabel}
              {editingValue ? ': ' : ''}
              <span className="font-black">{editingValue}</span>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

export default SearchableDropdown
