import { FC } from 'react'
import { gql, useQuery } from '@apollo/client'

import SearchableDropdown from '~/src/components/generic/SearchableDropdown'

import {
  GetCostCentersForAutocompleteQuery,
  GetCostCentersForAutocompleteQueryVariables,
} from '~/src/components/shared/__generated_types__/CostCenterAutocomplete.gql'

export const GET_COST_CENTERS = gql`
  query GetCostCentersForAutocomplete {
    getAllActiveCostCenters {
      id
      name
      code
    }
  }
`

const CostCenterAutocomplete: FC<{
  className?: string
  inputClassName?: string
  fuseThreshold?: number
  onChange: (
    c: GetCostCentersForAutocompleteQuery['getAllActiveCostCenters'][0]
  ) => void
  value?: string
  placeholder?: string
}> = ({ className, onChange, inputClassName, fuseThreshold, value, placeholder }) => {
  const { data } = useQuery<
    GetCostCentersForAutocompleteQuery,
    GetCostCentersForAutocompleteQueryVariables
  >(GET_COST_CENTERS)

  const costCenters = data?.getAllActiveCostCenters ?? []

  return (
    <SearchableDropdown
      className={className}
      placeholder={placeholder || 'Add cost centers'}
      value={value || ''}
      onSelect={(costCenter) => onChange(costCenter)}
      fuseThreshold={fuseThreshold}
      options={costCenters}
      searchKeys={['name']}
      renderOption={({ name }) => (
        <div className="py-1.5">
          <div className="text-xs font-medium">{name}</div>
        </div>
      )}
      inputClassName={inputClassName}
    />
  )
}

export default CostCenterAutocomplete
