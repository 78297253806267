import { KeyboardEvent, forwardRef, FocusEvent, ChangeEvent, MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'

export type InputPropsType = {
  className?: string
  placeholder?: string
  type?: string
  value: string | number | null
  onChange: (value: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  onClick?: (event: MouseEvent<HTMLInputElement>) => void
  disabled?: boolean
  autoFocus?: boolean
}

const Input = forwardRef<HTMLInputElement, InputPropsType>(
  (
    {
      className,
      placeholder,
      type = 'text',
      value,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      onClick,
      disabled,
      autoFocus,
    },
    ref
  ) => {
    return (
      <input
        ref={ref}
        autoComplete="off"
        placeholder={placeholder}
        className={twMerge(
          'h-10 w-full rounded border border-solid border-gray-75 px-2 py-1 text-xs text-gray-800 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600',
          className
        )}
        type={type}
        value={!value && value !== 0 ? '' : value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Escape') e.currentTarget.blur()

          onKeyDown?.(e)
        }}
        disabled={disabled}
        autoFocus={autoFocus}
        data-1p-ignore
      />
    )
  }
)

Input.displayName = 'Input'
export default Input
